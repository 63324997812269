import React from "react";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import "./card-quote.scss";

export const CardQuote = ({
  fullName,
  citation,
  position,
  image,
  insideSlider,
}) => {
  const parsedImage = getImage(image);
  return (
    <>
      <div className="card-quote">
        <div className="card-quote__content">
          <div className="quote-img-holder">
            <StaticImage
              src="../../images/illustration/quote.png"
              alt="Quote Icon"
              width={70}
              placeholder="blurred"
              className="quote-image"
            />
          </div>
          <div className="citation-holder">
            <p className="citation">{citation}</p>
          </div>
          <div className="details">
            <div className="author">
              <GatsbyImage
                image={parsedImage}
                alt={fullName}
                className="image"
              />
              <div className="info">
                <p className="name">{fullName}</p>
                <p className="position">{position}</p>
              </div>
            </div>
          </div>
          {insideSlider && (
            <>
              <div className="controls testimonials-slider__controls">
                <div className="swiper-button-prev feedback-prev"></div>
                <div className="swiper-button-next feedback-next"></div>
              </div>
              <div className="swiper-pagination"></div> {/* Conditionally rendered */}
            </>
          )}
        </div>
      </div>
    </>
  );
};
