import React from 'react';
import './items.scss';
import { IntroHolder } from '../IntroHolder/IntroHolder';
import classNames from 'classnames';

export const ItemsHolder = ({ title, children, twoColumn }) => {
  return (
    <>
      <section className={classNames("items", { "items--two-column": twoColumn })}>
        <div className="container">
          <div className="gr-12">
            {title && (
              <div className="items__title-holder">
                <IntroHolder
                  title={title}
                  level="h3"
                  centered
                  small
                />
              </div>
            )}
            <div className="items__wrap">
              {children}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
