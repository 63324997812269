import React from "react";
import "./card-mockup.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button } from "../Button/Button";

export const CardMockup = ({ title, description, image, link, linkText }) => {
  const parsedImage = getImage(image);

  return (
    <div className="card-mockup">
      <div className="card-mockup__image">
        <GatsbyImage image={parsedImage} alt={description || title} className="image" />
      </div>

      {title || description ? (
        <div className="card-mockup__data">
          {title && <h4 className="heading">{title}</h4>}
          {description && <p className="description">{description}</p>}
          
          {link && linkText && (
            <Button link={link} primary>
              {linkText}
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
};
